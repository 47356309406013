import styled from "styled-components";
import { EndSequence } from "./App";
import { useMemo } from "react";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 330px;
  width: 80vw;
  height: 475px;
  padding: 20px;
  background-color: white;
  color: #49323e;
  border-style: solid;
  border-color: #49323e;
  border-width: 0.5px;
  font-family: "Mulish";
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  line-height: 38px;
`;

export const ViewMore = ({
  endSequence,
}: {
  endSequence: EndSequence | undefined;
}) => {
  const options = useMemo(() => {
    return endSequence?.viewMore.options.map((option, key) => {
      return (
        <div
          key={key}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "220px",
            width: "100%",
            borderRadius: "10px",
            // backgroundColor: "#49323e",
            color: "white",
            fontSize: "20px",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          <a href={option.pageUrl}>
            <img
              src={option.posterUrl}
              alt="Social Anxienty poster"
              width={"180px"}
            />
          </a>
        </div>
      );
    });
  }, [endSequence?.viewMore.options]);

  return (
    <Wrapper>
      <Header>
        {endSequence?.viewMore.headerLine || "Are you ready for a new reality?"}
      </Header>
      <div
        style={{
          outline: "1px solid #49323e",
          width: "100%",
          height: "80%",
          borderRadius: "10px",
          padding: "5px",
          paddingRight: "0px",
          paddingLeft: "0px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "space-evenly",
        }}
      >
        {options}
      </div>
      {/* <p>I'll do this later</p> */}
    </Wrapper>
  );
};
