export const requestMotionPermission = async (event?: any): Promise<any> => {
  return new Promise((resolve) => {
    if (
      (DeviceOrientationEvent as any).requestPermission &&
      (DeviceMotionEvent as any).requestPermission
    ) {
      if (event) {
        try {
          (DeviceOrientationEvent as any)
            .requestPermission()
            .then((permissionState: any) => {
              (DeviceMotionEvent as any)
                .requestPermission()
                .then((motionPermissionState: any) => {
                  console.log(
                    "permissionState",
                    permissionState,
                    motionPermissionState
                  );
                  resolve({ haveSensors: true, needPermissions: false });
                })
                .catch((error: any) => {
                  console.error("Motion permission request error", error);
                  resolve({ haveSensors: true, needPermissions: true });
                });
            })
            .catch((error: any) => {
              console.error("Orientation permission request error", error);
              resolve(false);
            });
        } catch (error) {
          console.error("Permission request error", error);
          resolve(false);
        }
      } else {
        resolve({ haveSensors: true, needPermissions: true });
      }
    } else if (window.DeviceOrientationEvent && window.DeviceMotionEvent) {
      let isOrientationSupported = false;
      let isMotionSupported = false;

      const handleOrientation = (event: DeviceOrientationEvent) => {
        console.log(event);
        if (
          event.alpha !== null ||
          event.beta !== null ||
          event.gamma !== null
        ) {
          isOrientationSupported = true;
        }
        if (isOrientationSupported && isMotionSupported) {
          cleanup();
          resolve({ haveSensors: true, needPermissions: false });
        }
      };

      const handleMotion = (event: DeviceMotionEvent) => {
        console.log(event);
        if (
          (event.acceleration && event.acceleration!.x !== null) ||
          (event.accelerationIncludingGravity &&
            event.accelerationIncludingGravity.x !== null)
        ) {
          isMotionSupported = true;
        }
        if (isOrientationSupported && isMotionSupported) {
          cleanup();
          resolve({ haveSensors: true, needPermissions: false });
        }
      };

      const cleanup = () => {
        window.removeEventListener("deviceorientation", handleOrientation);
        window.removeEventListener("devicemotion", handleMotion);
      };

      window.addEventListener("deviceorientation", handleOrientation);
      window.addEventListener("devicemotion", handleMotion);

      setTimeout(() => {
        cleanup();
        if (isOrientationSupported && isMotionSupported) {
        } else {
          resolve({ haveSensors: false, needPermissions: false });
        }
      }, 1000); // Timeout to ensure events have a chance to fire
    } else {
      resolve({ haveSensors: false, needPermissions: false });
    }
  });
};
